import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Table, Button, Badge } from 'react-bootstrap';
import SvgDraw from './svgdraw';

function GameSetup(){
    return (
        <Container id="game-setup">
            <Row>
                <Col xm={8}>
                    <h3>Create a new game</h3>
                    <h5><em>These should be locked down by permission (or to coach only)</em></h5>
                </Col>
                <Col xm={{offset:8}}>
                    <span>Game</span>
                    <Badge pill bg="secondary">2/6</Badge>{' '}
                    <Badge pill bg="secondary">4/30/2022</Badge>{' '}
                    <Button size="sm" variant="secondary">Next</Button>{' '}
                    <Button size="sm" variant="secondary">Prev</Button>{' '}
                    <Button size="sm" variant="success">New Game</Button>{' '}
                    <Button size="sm" variant="danger">Delete</Button>
                </Col>
            </Row>
            <Row sm={12}>
                <Col sm={8}>
                    <h5>Game Title ( Us Vs. Bluejays 4/30/20223 )</h5>
                    <SvgDraw width="100%" height="65%" thirdBase="Bobby Knight" />
                </Col>
                <Col sm={4}>
                    <Row>
                        <Table bordered size="sm">
                            <thead>
                                <tr>                                  
                                    <th>Inning</th>
                                    <th>Actions</th>
                                    <th><Button variant="outline-secondary" size="sm">Add</Button></th>                                
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td style={{'text-align':'center'}}>1</td>
                                    <td colSpan="2">
                                        <Button variant="success" size="sm">View</Button>{' '}
                                        <Button variant="danger" size="sm">Remove</Button>
                                    </td>
                                </tr><tr>
                                    <td style={{'text-align':'center'}}>2</td>
                                    <td colSpan="2">
                                        <Button variant="warning" size="sm">View</Button>{' '}
                                        <Button variant="danger" size="sm">Remove</Button>
                                    </td>
                                </tr ><tr>
                                    <td style={{'text-align':'center'}}>3</td>
                                    <td colSpan="2">
                                        <Button variant="warning" size="sm">View</Button>{' '}
                                        <Button variant="danger" size="sm">Remove</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Table bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Player</th>
                                    <th>Innings</th>
                                    <th>Positions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                                <tr><td>Tommy Smith</td><td>2</td><td>2</td></tr>
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}


export default GameSetup;