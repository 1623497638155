import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Table } from 'react-bootstrap';

function PlayerStats(){
    return (
        <Container id="player-stats-screen">
            <Row>
                <h3>Player Stats</h3>
                <h5><em>Should be locked to head coach only (or other perm)</em></h5>
            </Row>
            <Row>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Practice Attendence</th>
                            <th>Game Attendence</th>
                            <th>Innings Played</th>
                            <th>Desired Positions</th>
                            <th>Can Pitch</th>
                            <th>Wants to Pitch</th>
                            <th>Batting Average</th>
                            <th>Connecting Average</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Jimmy Buffet Jr</td><td>9/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Dallas Carter</td><td>10/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Dominic Corleon</td><td>9/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Felix Smith</td><td>5/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Teddy White</td><td>10/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Jet Goodrich</td><td>10/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Buster McCalister</td><td>10/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Timmy Robinson</td><td>7/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Billy Buttler</td><td>3/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                        <tr><td>Rodrigo</td><td>10/10</td><td>4/4</td><td>16</td><td>Catcher,RF</td><td>Yes</td><td>Yes</td><td>.27</td><td>.4</td></tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}


export default PlayerStats;