import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Table } from 'react-bootstrap';
import AddPlayerModal from './AdddPlayerModal';

function Players(){
    return (
        <Container id="players-screen">
            <Row>
                <Col>
                    <h3>Players</h3>
                </Col>
                <Col>
                    <AddPlayerModal />
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Jimmy Buffet Jr</td><td>10</td></tr>
                        <tr><td>Dallas Carter</td><td>12</td></tr>
                        <tr><td>Dominic Corleon</td><td>11</td></tr>
                        <tr><td>Felix Smith</td><td>11</td></tr>
                        <tr><td>Teddy White</td><td>10</td></tr>
                        <tr><td>Jet Goodrich</td><td>10</td></tr>
                        <tr><td>Buster McCalister</td><td>10</td></tr>
                        <tr><td>Timmy Robinson</td><td>10</td></tr>
                        <tr><td>Billy Buttler</td><td>10</td></tr>
                        <tr><td>Rodrigo</td><td>10</td></tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}


export default Players;