import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

function TeamStats(){
    return (
        <Container id="team-stats-screen">
            <Row>
                <h3>Team Stats</h3>
                <h5><em>These should be locked down by permission (or to coach only)</em></h5>
            </Row>
            <Row>
                <p>Some Cards would be good here</p>
                <Col>
                    <Card style={{ width: '18rem' }}>
                      <Container>
                          <Row>
                              <Col>Goal</Col>
                              <Col>Last</Col>
                              <Col>Avg</Col>
                          </Row>
                          <Row>
                              <Col>4</Col>
                              <Col>5</Col>
                              <Col>3.4</Col>
                          </Row>
                      </Container>
                      <Card.Img variant="top" src="holder.js/100px180" alt="Need to place an svg here for this" />
                      <Card.Body>
                        <Card.Title>MultiTouch Plays</Card.Title>
                        <Card.Text>
                          When the defense gets to work, how well do we work as a team?
                        </Card.Text>
                      </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '18rem' }}>
                      <Container>
                          <Row>
                              <Col>Goal</Col>
                              <Col>Last</Col>
                              <Col>Avg</Col>
                          </Row>
                          <Row>
                              <Col>10</Col>
                              <Col>9</Col>
                              <Col>8.8</Col>
                          </Row>
                      </Container>
                      <Card.Img variant="top" src="holder.js/100px180" alt="Need to place an svg here for this" />
                      <Card.Body>
                        <Card.Title>In Play Shifts</Card.Title>
                        <Card.Text>
                          The ball is in play, does the defense go where it is supposed to?
                        </Card.Text>
                      </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}


export default TeamStats;