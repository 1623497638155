import React, { useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";


function AddPlayerModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const handleSubmit = (event) => {
        setShow(false);
        event.preventDefault();
        
    };
    
    return (
        <>
            <Button variant="secondary" size="sm" onClick={handleOpen}>
                Add Player
            </Button>

            <Modal 
                show={show} 
                onHide={handleClose} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Player Details
                    </Modal.Title>
                </Modal.Header>
                    <Form>
                <Modal.Body>
                        <FloatingLabel controlId="playerName" label="Player Name">
                            <Form.Control type="text" placeholder="Player Name"/>
                        </FloatingLabel>
                        <FloatingLabel controlId="playerBirthday" label="Player Birthday">
                            <Form.Control type="date" placeholder="Player Birthday"/>
                        </FloatingLabel>
                        <FloatingLabel controlId="playerAlergies" label="Player Alergies">
                            <Form.Control type="textarea" placeholder="Player Alergies"/>
                        </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
                    </Form>
            </Modal>
        </>
    );
}

export default AddPlayerModal;