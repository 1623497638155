import * as React from "react"

const SvgDraw = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="210mm"
    height="297mm"
    viewBox="0 0 210 297"
    {...props}
  >
    <defs>
      <path id="a" d="M18.175 192.216h61.871v33.888H18.175z" />
      <path id="b" d="M18.175 192.216h59.669v29.536H18.175z" />
      <path id="c" d="M18.175 192.216h61.871v33.888H18.175z" />
      <path id="h" d="M18.175 192.216H94.96v15.595H18.175z" />
      <path id="d" d="M18.175 192.216h80.83v28.624h-80.83z" />
      <path id="i" d="M18.175 192.216h82.474v15.463H18.175z" />
      <path id="e" d="M18.175 192.216H94.96v15.595H18.175z" />
      <path id="f" d="M18.175 192.216H94.96v15.595H18.175z" />
      <path id="g" d="M18.175 192.216H94.96v15.595H18.175z" />
    </defs>
    <path
      style={{
        fill: "green",
        fillRule: "evenodd",
        strokeWidth: 0.361057,
      }}
      transform="rotate(45)"
      d="M153.77 9.911h127.279V137.19H153.77z"
    />
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#a)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 .88 39.304)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan
          style={{
            strokeWidth: 1.00039,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
          }}
        >
          {props.thirdBase}
        </tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#b)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 125.998 39.08)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"First Base"}</tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#c)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 62.564 29.53)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Pitcher"}</tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#d)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 59.268 108.799)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Catcher"}</tspan>
      </tspan>
    </text>
    <path
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m11.724 205.74-45.658-46.597M191.724 205.74l46.693-46.075M-33.934 159.143l90-90M238.417 159.665l-90-90M56.066 69.143c15.231-14.952 30.462-29.904 45.854-29.817 15.392.088 30.945 15.213 46.497 30.339"
    />
    <path
      style={{
        fill: "#fca",
        fillOpacity: 1,
        strokeWidth: 1.21913,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      d="m-11.105 182.442-22.597-23.085 49.73-49.663c47.17-47.107 54.146-53.88 60.787-59.025 6.124-4.744 12.684-8.383 17.647-9.789 2.955-.837 8.338-1.16 11.152-.67 8.429 1.468 17.486 6.997 30.439 18.582 4.126 3.69 101.453 100.643 101.453 101.063 0 .116-10.368 10.448-23.039 22.961l-23.039 22.752-44.994-44.994-44.994-44.993-44.974 44.972-44.973 44.973Z"
    />
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#e)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 -4.909 -39.853)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Left Field"}</tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#f)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 54.255 -84.063)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Center Field"}</tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#g)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 140.31 -22.88)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Right Field"}</tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#h)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 58.256 -33.029)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan
          style={{
            strokeWidth: 1.00039,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
          }}
        >
          {"Second "}
        </tspan>
        <tspan
          style={{
            strokeWidth: 1.00039,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
          }}
        >
          {"Base"}
        </tspan>
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10.5833px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        whiteSpace: "pre",
        shapeInside: "url(#i)",
        fill: "#000",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 1.00039,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="matrix(.85908 0 0 .84327 17.914 -1.901)"
    >
      <tspan x={18.176} y={201.579}>
        <tspan>{"Short Stop"}</tspan>
      </tspan>
    </text>
  </svg>
)

export default SvgDraw
