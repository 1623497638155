import { Button, ButtonGroup, Container, DropdownButton, FloatingLabel, Form, FormSelect, Row, Col, Table, FormText, FormControl } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";


function PlayGame(){
  return (
    <>
    <Container>
        <Row>
            <h3>Bluejays vs. RedHawks 4/23/2022</h3>
        </Row>
        <Row>
            <Col>
                <Form>
                    <FloatingLabel label="Batter" controlId="player">
                        <FormControl label="Batter" />
                    </FloatingLabel>
                    <br />
                    <FloatingLabel label="Pitch Location" controlId="verticalLocation">
                        <FormSelect size="lg" label="Pitch Location">
                            <option></option>
                            <option value="high">High</option>
                            <option value="mid">Mid</option>
                            <option value="low">Low</option>                    
                        </FormSelect>
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label="Pitch Location" controlId="horizontalLocation">
                        <FormSelect size="lg" label="Pitch Location">
                            <option></option>
                            <option value="inside">Inside</option>
                            <option value="center">Center</option>
                            <option value="outside">Outside</option>
                        </FormSelect>
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label="Strike Appearance" controlId="inoutzone">
                        <FormSelect size="lg" label="Strike Appearance">
                            <option></option>
                            <option value="in">In</option>
                            <option value="out">Out</option>
                        </FormSelect>
                    </FloatingLabel>
                    <br/>   
                    <DropdownButton as={ButtonGroup} title="Outcome" size="lg">
                        <DropdownItem eventKey="swung-strike">Swung Strike</DropdownItem>
                        <DropdownItem eventKey="called-strike">Called Strike</DropdownItem>
                        <DropdownItem eventKey="ball">Ball</DropdownItem>
                        <DropdownItem eventKey="foul">Foul</DropdownItem>
                        <DropdownItem eventKey="hit">Hit</DropdownItem>
                        <DropdownItem eventKey="hit-by-pitch">Hit By Pitch</DropdownItem>
                    </DropdownButton>
                </Form>
                <Table size="sm">
                    <thead><tr><th>Ball</th><th>Strike</th><th>Foul</th></tr></thead>
                    <tbody>
                        <tr><td>3</td><td>2</td><td>5</td></tr>
                    </tbody>
                </Table>
                <Button>View Fielders</Button>{' '}
                <Button>View Lineup</Button>{' '}
                <Button>Substitue Player</Button>
            </Col>
            <Col>
                <Table size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Hits</th>
                            <th>Batters</th>
                            <th>Runs</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>BlueJays</td>
                            <td>3</td>
                            <td>13</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>RedHawks</td>
                            <td>7</td>
                            <td>12</td>
                            <td>5</td>
                        </tr>
                    </tbody>
                </Table>
                <h5>BlueJays Pitchers</h5>
                <Table size="sm">
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Innings
                            </th>
                            <th>
                                Pitch Count
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Tommy Woodruff</td>
                            <td>5</td>
                            <td>43</td>
                        </tr>
                    </tbody>
                </Table>
                <Button size="sm">Change Pitcher</Button>
                <h5>RedHawks Pitchers</h5>
                <Table size="sm">
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Innings
                            </th>
                            <th>
                                Pitch Count
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Dom Salvador</td>
                            <td>3</td>
                            <td>35</td>
                        </tr>
                        <tr>
                            <td>Jimmy Buffet Jr.</td>
                            <td>2</td>
                            <td>18</td>
                        </tr>
                    </tbody>
                </Table>
                <Button size="sm">Pitcher Change</Button>
            </Col>
        </Row>
    </Container>
    </>    
  );
}

export default PlayGame;