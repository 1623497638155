import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Row, Col, Nav, Accordion } from 'react-bootstrap';
import Players from './Players'
import Parents from './Parents';
import PlayerStats from './PlayerStats';
import TeamStats from './TeamStats';
import GameSetup from './GameSetup';
import PlayGame from './PlayGame';

function App() {
  return (
    <div className="App">
      <Tab.Container className="flex-column">
        <Row>
          <Col sm={3}>
            <Accordion flush>
              <Accordion.Header>Team</Accordion.Header>
              <Accordion.Body>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="team-players">Players</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="team-parents">Parents</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="stats-player">Player Stats</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="stats-team">Team Stats</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Accordion.Body>
            </Accordion>
            <Accordion flush>
              <Accordion.Header>Games</Accordion.Header>
              <Accordion.Body>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="game-setup">Game Setup</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="game-play">Play Game</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="game-view">View Games</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Accordion.Body>
            </Accordion>
            <Accordion flush>
              <Accordion.Header>Practice</Accordion.Header>
              <Accordion.Body>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="practice-setup">Setup Practice</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="practice-play">Track Practice</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Accordion.Body>
            </Accordion>                      
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="team-players">
                <Players />
              </Tab.Pane>
              <Tab.Pane eventKey="team-parents">
                <Parents />
              </Tab.Pane>
              <Tab.Pane eventKey="stats-player">
              <PlayerStats />
              </Tab.Pane>
              <Tab.Pane eventKey="stats-team">
                <TeamStats />
              </Tab.Pane>
              <Tab.Pane eventKey="game-setup">
                <GameSetup />
              </Tab.Pane>
              <Tab.Pane eventKey="game-play">
                <PlayGame />
              </Tab.Pane>
              <Tab.Pane eventKey="game-view">
                View Games
              </Tab.Pane>
              <Tab.Pane eventKey="practice-setup">
                Setup a new practice
              </Tab.Pane>
              <Tab.Pane eventKey="practice-play">
                Track (play) a practice
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default App;
