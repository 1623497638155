import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Table } from 'react-bootstrap';

function Parents(){
    return (
        <Container id="parents-screen">
            <Row>
                <h3>Parents</h3>                
            </Row>
            <Row>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Child</th>
                            <th>e-mail</th>
                            <th>phone</th>
                            <th>can coach?</th>
                            <th>is emergency contact?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td></td><td>Jimmy Buffet Jr</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Jimmy Buffet Jr</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Dallas Carter</td><td></td><td></td><td></td><td>  </td></tr>
                        <tr><td></td><td>Dallas Carter</td><td></td><td></td><td></td><td>  </td></tr>
                        <tr><td></td><td>Dallas Carter</td><td></td><td></td><td></td><td>  </td></tr>
                        <tr><td></td><td>Dominic Corleon</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Dominic Corleon</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Felix Smith</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Teddy White</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Teddy White</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Teddy White</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Teddy White</td><td></td><td></td><td></td><td>    </td></tr>
                        <tr><td></td><td>Jet Goodrich</td><td></td><td></td><td></td><td>   </td></tr>
                        <tr><td></td><td>Jet Goodrich</td><td></td><td></td><td></td><td>   </td></tr>
                        <tr><td></td><td>Buster McCalister</td><td></td><td></td><td></td><td>  </td></tr>
                        <tr><td></td><td>Timmy Robinson</td><td></td><td></td><td></td><td> </td></tr>
                        <tr><td></td><td>Billy Buttler</td><td></td><td></td><td></td><td>  </td></tr>
                        <tr><td></td><td>Rodrigo</td><td></td><td></td><td></td><td>    </td></tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}


export default Parents;